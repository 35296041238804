declare global {
  interface Window {
    downtimeData: any;
  }
}

export default function initDowntimeListView() {
  const systemDowntimeContent = document.querySelector(
    '.system-downtime-content'
  );

  if (!systemDowntimeContent) return;

  try {
    if (!window.downtimeData) return;
    const pageDataUrl = window.downtimeData;
    fetch(pageDataUrl)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        });

				handleButtons();

        const listView = document.getElementById('listView');
        const listViewTable = listView.querySelector('table tbody');

        let currentMonth = '';
        data.forEach((item) => {
          const startDate = new Date(item.startDate);
          const month = startDate.toLocaleString('default', {
            month: 'long',
            timeZone: 'UTC',
          });
					const year = startDate.getFullYear();
					const endDate = new Date(item.endDate);

          const impactedSystems = item.impact.reduce((acc, impact) => {
            return acc.concat(impact.systems);
          }, []);

          // Add a new row for each month
          if (month !== currentMonth) {
            const monthRow = document.createElement('tr');
						monthRow.setAttribute("class", "month");
            monthRow.innerHTML = `
                        <td colspan="4" class="month"">${month} ${year}</td>
                      `;
            listViewTable.appendChild(monthRow);
            currentMonth = month;
          }

					const row = document.createElement('tr');

					const currentDate = new Date().toLocaleDateString('en-us', { 
						year: "numeric", 
						day: "numeric", 
						month: "short" })

					// Compare downtime ending date to today's date and mark as upcoming or previous via class name
					let dateType = (new Date(endDate).getTime() + ( 3600 * 1000 * 24) > new Date(currentDate).getTime()) ? "upcoming" : "previous";
					row.setAttribute("class", dateType);

					// Build table td tags
          row.innerHTML = `
                      <td data-title="From">
                        <span>${startDate.toLocaleDateString('en-US', {
                          weekday: 'long',
                          timeZone: 'UTC',
                        })}</span><br />
                        <strong>${startDate.toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        })}  ${item.startTime ? item.startTime : ''}</strong>
                      </td>
                      <td data-title="To">
                        <span>${new Date(item.endDate).toLocaleDateString(
                          'en-US',
                          {
                            weekday: 'long',
                            timeZone: 'UTC',
                          }
                        )}</span><br />
                        <strong>${new Date(item.endDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC',
                          }
                        )} ${item.endTime ? item.endTime : ''}</strong>
                      </td>
                      <td data-title="Impacted Systems">
                        ${impactedSystems
                          .map((system) => `<strong>${system}</strong>`)
                          .join('<br />')}
                      </td>
                      <td data-title="Type">
                        <div class="tag tag-${
                          item.type === 'downtime' ? 'error' : 'primary'
                        }">
                          <i class="icon icon-${
                            item.type === 'downtime' ? 'clock' : 'wrench'
                          }"></i>
                          ${
                            item.type === 'downtime'
                              ? 'System Downtime'
                              : 'Maintenance Window'
                          }
                        </div>
                      </td>
                    `;
          listViewTable.appendChild(row);
					showRows("upcoming");
        });
      });
  } catch (error) {
    console.error(error);
  }

	function handleButtons() {
		// Set button titles
		const upcomingLabel = document.querySelector('label[for="upcoming"]');
		if (upcomingLabel)
			upcomingLabel.innerHTML =
				'<i class="icon icon-check"></i> Upcoming Downtime';

		const previousLabel = document.querySelector('label[for="previous"]');
		if (previousLabel)
			previousLabel.innerHTML =
				'<i class="icon icon-check"></i> Previous Downtime';

		// Handle button clicks
		document.getElementById('upcoming')?.addEventListener('change', (e) => {
			document.getElementById('listDisplay')!.innerText = "Upcoming Downtime & Maintenance";
			showRows("upcoming");
		});

		upcomingLabel?.addEventListener('keypress', (e: any) => {
			if (e.key !== 'Enter') return;
			document.getElementById('upcoming')?.click();
		});

		document.getElementById('previous')?.addEventListener('change', (e) => {
			document.getElementById('listDisplay')!.innerText = "Previous Downtime & Maintenance";
			showRows("previous");
		});

		previousLabel?.addEventListener('keypress', (e: any) => {
			if (e.key !== 'Enter') return;
			document.getElementById('previous')?.click();
		});
	}

	// Show only rows that match current view (upcoming or previous)
	function showRows(listType) {
		var table = document.getElementsByClassName("system-downtime-table table-complex")[0];
		var rows = table.querySelectorAll("tr");
		var currentMonthHeading = rows[0];
	
		rows.forEach(function(row) {
			row.setAttribute("style", "display:none");
			if (row.className === "month") {
				currentMonthHeading = row;
			} else {
				if (row.className === listType) {
					row.setAttribute("style", "display:normal");
					currentMonthHeading.setAttribute("style", "display:normal");
				} else {
					row.setAttribute("style", "display:none");
				}
			}
		});  

		// Make sure table header always shows
		rows[0].setAttribute("style", "display:normal"); 
	}
}
